const config = {
  email: 'kateryna.sheruda@gmail.com',
  phone: '+31627542745',
  cvLink: '/cv.pdf',
  caseAtidaLink: '/case_atida.pdf',
  caseLenderKitLink: '/case_lenderkit.pdf',
  linkedin: 'https://www.linkedin.com/in/kateryna-sheruda/',
  behance: 'https://www.behance.net/katsprrw',
  dribbble: 'https://dribbble.com/katsprrw',
  instagram: 'https://www.instagram.com/katsprrw/'
};

export default config;
