import './Social.css';
import LinkedinIcon from '../../assets/Linkedin.svg';
import BehanceIcon from '../../assets/Behance.svg';
import DribbbleIcon from '../../assets/Dribbble.svg';
import InstagramIcon from '../../assets/Instagram.svg';

const iconMap = {
  'linkedin': LinkedinIcon,
  'behance': BehanceIcon,
  'dribbble': DribbbleIcon,
  'instagram': InstagramIcon,
};

const openInNewTab = url => window.open(url, '_blank', 'noopener,noreferrer');

export default function Social({ title, subTitle, icon, url }) {
  return (
    <div className={'social-item'} onClick={() => openInNewTab(url)}>
      <div>
        <h5>{title}</h5>
        <span className={'secondary'}>{subTitle}</span>
      </div>
      <img className={'img-icon'} src={iconMap[icon]}/>
    </div>
  );
}
