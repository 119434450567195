import './Footer.css';
import ArrowRight from '../../assets/ArrowRight.svg';
import Social from '../../Components/Social/Social';

export default function Footer({ email, phone, cvLink, linkedinUrl, behanceUrl, dribbbleUrl, instagramUrl }) {
  return (
    <div id={'footer'} className={'footer-container'}>
      <div className={'contact'}>
        <div className={'left'}>
          <div className={'row-1'}>
            <div className={'secondary label'}>
              <span>email:</span>
              <a target={'_blank'}
                  rel={'noreferrer'}
                  href={`mailto:${email}?subject=Hi!&body=Hi Kateryna! I\'m {username} and I\'d like to talk to you about {text}`}>{email}</a>
            </div>
            <div className={'secondary label'}>
              <span>phone:</span>
              <a target={'_blank'}
                 rel={'noreferrer'}
                 href={`tel:${phone}`}>{phone}</a>
            </div>
          </div>
          <div className={'row-2'}>
            <a href={cvLink} download="cv-kateryna_sheruda.pdf">
              download cv
              <span className={'icon icon-download'}/>
            </a>
          </div>
        </div>
        <div className={'right'}>
          <span className={'footer-header'}>let's get</span>
          <div className={'row-2'}>
            <span className={'footer-header'}>in touch</span>
            <img src={ArrowRight}/>
          </div>
        </div>
      </div>
      <div className={'social'}>
        <Social title={'Linkedin'} subTitle={'@kateryna-sheruda'} icon={'linkedin'} url={linkedinUrl}/>
        <Social title={'Behance'} subTitle={'@katsprrw'} icon={'behance'} url={behanceUrl}/>
        <Social title={'Dribbble'} subTitle={'@katsprrw'} icon={'dribbble'} url={dribbbleUrl}/>
        <Social title={'Instagram'} subTitle={'@katsprrw'} icon={'instagram'} url={instagramUrl}/>
      </div>
      <div className={'rights'}>
        <span className={'body-text'}>© 2023 Portfolio Kateryna Sheruda – All Rights Reserved.</span>
        <a href={"#top"} className={'secondary'}>back to top</a>
      </div>
    </div>
  );
}
