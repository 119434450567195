import { QueryClientProvider, QueryClient } from 'react-query';
import './App.css';
import './assets/fonts/MAZIUSREVIEW20.09-Italic.woff';
import Experience from './Containers/Experience/Experience';
import Hero from './Containers/Hero/Hero';
import About from './Containers/About/About';
import Footer from './Containers/Footer/Footer';
import config from './config';

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <div className={'content-container'}>
        <Hero/>
        <About/>
        <Experience/>
        <Footer email={config.email} phone={config.phone} cvLink={config.cvLink} linkedinUrl={config.linkedin}
                behanceUrl={config.behance} dribbbleUrl={config.dribbble} instagramUrl={config.instagram}/>
      </div>
    </QueryClientProvider>
  );
}

export default App;
