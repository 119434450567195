import './About.css';

export default function About() {
  return (
    <div id={'about'} className={"about-container"}>
      <div className={"content"}>
        <h3>a bit <span>about</span> me</h3>
        <p>
          I've been working as a UI/UX designer for more than 4 years.
          I'm passionate about improving the lives of others through design and am constantly looking to learn new things everyday.
          That's why my approach is to put users and their needs first.
        </p>
        <p>
          When I'm not focusing on solutions for my future designs, you can find me walking with my lovely dog Itachi, drawing landscapes and human beings, and creating home interior projects.
        </p>
        <p>
          I would love the opportunity to help your team to build a better product, so don't hesitate to reach out to me!
        </p>
        {/*<ul>*/}
        {/*  <li>Achievement 1</li>*/}
        {/*  <li>Achievement 2</li>*/}
        {/*  <li>Achievement 3</li>*/}
        {/*</ul>*/}
      </div>
      <div>
        <span className={'me'}/>
      </div>
    </div>
  );
}
