import './ProfessionalExperience.css';
import ArrowSmall from '../../assets/ArrowSmall.svg';
import useWindowDimensions from '../../Hooks/useWidndowsDimensions';

function ExperienceLabel({ text, primary }) {
  return (
    <span className={`experience-label ${primary ? 'primary' : ''}`}>{text}</span>
  );
}


export default function ProfessionalExperience({ title, externalLink, experienceImg, labels, position, caseLink, caseFilename }) {
  const { isMobile } = useWindowDimensions();

  const visitWebsite = (
    <div className={'external-link secondary'}>
      <a href={externalLink} target="_blank">
        visit website
        <img src={ArrowSmall}/>
      </a>
    </div>
  )

  const viewCase =  caseLink ? (
    <div className={'external-link secondary'}>
      <a href={caseLink} download={caseFilename}>
        view case
        <img src={ArrowSmall}/>
      </a>
    </div>
  ): null;

  return (
    <div className={`professional-experience ${position % 2 === 0 ? 'right' : 'left'}`}>
      <img className={'experience-img'} src={experienceImg}/>
      <div className={'title'}>
        <a className={'source-website'} href={externalLink} target={"_blank"}><h3 className={'italic'}>{title}</h3></a>
        {!isMobile ? viewCase || visitWebsite : null}
      </div>
      <div className={'labels'}>
        {labels.map((it, i) => (<ExperienceLabel text={it} primary={i === 0}/>))}
      </div>
      {isMobile ? viewCase || visitWebsite : null}
    </div>
  );
}
