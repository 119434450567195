import './Hero.css';
import ArrowRight from '../../assets/ArrowRight.svg';
import AnchorLink from '../../Components/AnchorLink/AnchorLink';
import useWindowDimensions from '../../Hooks/useWidndowsDimensions';

export default function Hero({}) {
  const { isLaptop, isMobile } = useWindowDimensions();

  const labels = (
    <div className={'label-row'}>
      <div className={'secondary header-label'}>
        <span>currently atida's</span>
        <span>product designer</span>
      </div>
      <div className={'secondary header-label'}>
        <span>based in amsterdam</span>
        <span>the netherlands</span>
      </div>
    </div>
  );

  return (
    <div className={'hero-container'}>
      <div className={'header'}>
        <AnchorLink link={'top'}>{isLaptop ? 'kateryna sheruda' : 'k.s.'}</AnchorLink>
        <AnchorLink link={'about'}>about</AnchorLink>
        <AnchorLink link={'experience'}>works</AnchorLink>
        <AnchorLink link={'footer'}>contact</AnchorLink>
      </div>
      <div className={'content'}>
        <div className={'row-1'}>
          <h1>I'm Kateryna</h1>
          {isLaptop ? labels : null}
        </div>
        <div className={'row-2'}>
          <img src={ArrowRight}/>
          {
            isMobile
              ? <h1>I do</h1>
              : null
          }
          {
            isMobile
              ? <h1 className={'no-margin'}><span>UI & UX</span> design</h1>
              : null
          }
          {
            !isMobile
              ? <h1>I do <span>UI & UX</span> design</h1>
              : null
          }
        </div>
        {!isLaptop ? labels : null}
      </div>
    </div>
  );
}
