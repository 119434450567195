import './Experience.css';
import ProfessionalExperience from '../../Components/ProfessionalExperience/ProfessionalExperience';
import AtidaExperienceImg from '../../assets/experiences/atida_online_pharmacy.png';
import OONNIExperienceImg from '../../assets/experiences/oonni_b2b_marketplace.png';
import LenderKitExperienceImg from '../../assets/experiences/lenderkit_investment_platform.png';
import config from '../../config';

const experiences = [
  {
    title: 'Atida Online Pharmacy',
    img: AtidaExperienceImg,
    externalLink: 'https://www.atida.com/es-es',
    labels: ['e-commerce', 'ui/ux', 'research', 'ds support', 'user testing'],
    caseLink: config.caseAtidaLink,
    caseFilename: 'k.sheruda-case-atida.pdf'
  },
  {
    title: 'LenderKit investment platform',
    img: LenderKitExperienceImg,
    externalLink: 'https://lenderkit.com/',
    labels: ['fintech', 'ui/ux', 'research', 'design review'],
    caseLink: config.caseLenderKitLink,
    caseFilename: 'k.sheruda-case-lenderkit.pdf'
  },
  {
    title: 'OONNI B2B marketplace',
    img: OONNIExperienceImg,
    externalLink: 'https://oonni.com/',
    labels: ['e-commerce', 'ui/ux', 'research', 'design review', 'leadership']
  },
]

export default function Experience() {
  return (
    <div id={'experience'} className={'experience-container'}>
      <h2>my <span>best</span> works</h2>
      <div className={'content'}>
        {
          experiences.map((it, index) => <ProfessionalExperience
            title={it.title}
            experienceImg={it.img}
            externalLink={it.externalLink}
            labels={it.labels}
            position={index + 1}
            caseLink={it.caseLink}
            caseFilename={it.caseFilename}
          />)
        }
      </div>
    </div>
  );
}
